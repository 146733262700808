import React from "react"
import styled from "styled-components"
import { Helmet } from "react-helmet"

import classnames from "classnames"
import {
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import {
  already,
  Banner,
  BlockImageTextHalf,
  CodexItem,
  EdgeBorderFluid,
  Hashtags,
  LogoHeading,
  ReferencesUsers,
  Slider,
  TextBoxImageTop,
  TextPrimary,
  TitleH2Center,
  Button,
  ButtonStyle,
} from "@igloonet-web/shared-ui"
import { ContactForm, Layout } from "../components"

import IconAnalytica from "../icons/analytics.svg"
import IconBoard from "../icons/board.svg"
import IconConnect from "../icons/connect.svg"
import IconConsulting from "../icons/consulting.svg"
import IconCv from "../icons/cv.svg"
import IconMarketing from "../icons/marketing.svg"
import IconNetwork from "../icons/network.svg"
import IconResearch from "../icons/research.svg"
import IconResponsive from "../icons/responsive.svg"
import IconRocket from "../icons/rocket.svg"
import IconTarget from "../icons/target.svg"
import IconWebpage from "../icons/webpage.svg"

import LogoSNBZ from "../images/reference/snowboard-zezula/logo.png"

import LogoKiwi from "../images/reference/kiwi/logo.png"
import BrandImageKiwi_1x from "../images/reference/kiwi/brand-image_1x.jpg"
import BrandImageKiwi_2x from "../images/reference/kiwi/brand-image_2x.jpg"

import LogoRegiojet from "../images/reference/student-agency/logo.svg"
import BrandImageSA from "../images/reference/student-agency/sa.jpg"

import LogoVUT from "../images/reference/vutbrno/logo.png"
import BrandImageVUT from "../images/reference/fsivut/brand-image.jpg"

import LogoTMobile from "../images/reference/t-mobile/logo.png"
import BrandImageTmobile from "../images/reference/t-mobile/brand-image.jpg"

import LogoIFE from "../images/reference/ife/logoife.png"
import BrandImageIFE_1x from "../images/reference/ife/brand-image_1x.jpg"
import BrandImageIFE_2x from "../images/reference/ife/brand-image_2x.jpg"

import ProfileAdam from "../images/team/profile/adam.jpg"
import ProfileLukas from "../images/team/profile/lukas.jpg"
import ProfileKlaudie from "../images/team/profile/klaudie.jpg"
import ProfileMyval from "../images/team/profile/myval.jpg"
import ProfileDavid from "../images/team/profile/david.jpg"

import IFEPerson from "../images/reference/ife/fiser.png"
import ZezulaPerson from "../images/reference/snowboard-zezula/reaction-marketing.png"
import KiwiPerson from "../images/reference/kiwi/pezlarova.jpg"

const TechWrapper = styled.div`
  padding: 50px 0;
  border-top: 1px solid ${(props) => props.theme.color.gray300};
  background: ${(props) => props.theme.color.white};
`

const TechNav = styled.div`
  background: ${(props) => props.theme.color.gray200};

  .nav-tabs {
    padding-bottom: 20px;
    display: block;
    border: none;

    .nav-link {
      padding: 20px 15px;
      border: 1px solid transparent;
      background: transparent;
      color: ${(props) => props.theme.color.gray500};
      cursor: pointer;
      font-size: 14px;
      font-weight: 700;

      &.active {
        border: 1px solid ${(props) => props.theme.color.gray300};
        background: ${(props) => props.theme.color.white};
        border-radius: 4px;
        color: ${(props) => props.theme.color.blue};

        &:hover,
        &:focus,
        &:active {
          color: ${(props) => props.theme.color.blue};
        }
      }

      &:hover,
      &:focus,
      &:active {
        color: ${(props) => props.theme.color.primary};
      }
    }

    @media (min-width: ${(props) => props.theme.sizes_min.sm}) {
      padding-bottom: 0;
      display: flex;

      .nav-link {
        &.active {
          border-bottom: 1px solid ${(props) => props.theme.color.white};
        }
      }
    }
  }
`

export default class HrMarketing extends React.Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      activeTab: "1",
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  render() {
    const usersReferences = [
      {
        id: 1,
        imageSrc: LogoVUT,
        // imagePerson: chybí,
        imageAlt: "Logo VUT",
        name: "prof. RNDr. Radim Chmelík, Ph.D,",
        position: "proděkan FSI VUT",
        linkHref: "/marketing/reference/fsivut",
        linkTitle: "jak pomáháme vysokým školám s náborem studentů",
        text: "Se spoluprací s firmou igloonet jsme velmi spokojeni. Jejich příprava i realizace reklamních kampaní se vyznačuje bezproblémovou komunikací o cílení, časových požadavcích, náplni i finančních otázkách. Provedení přispělo k nečekaně vyššímu počtu podaných přihlášek do bakalářského studia, a to v situaci, kdy na srovnatelných fakultách v ČR tyto počty klesají.",
      },
      {
        id: 2,
        imageSrc: LogoIFE,
        imagePerson: IFEPerson,
        imageAlt: "Logo IFE",
        name: "Vít Fišer",
        position: "HR Marketing specialista, IFE",
        linkHref: "/marketing/reference/ife",
        linkTitle: "jak pomáháme firmám s náborem zaměstnanců",
        text: "igloonet nám pomohl navýšit objem životopisů řádově o desítky a návštěvnost kariérních stránek v řádech tisíců uživatelů měsíčně, díky čemuž jsme mohli obsadit mnoho volných pozic ve výrobě i kancelářích. Oceňuji zejména flexibilní reakce na naše požadavky, propracovanou strukturu online kampaní, transparentní komunikaci, pravidelný kvalitní reporting a aktivitu nad rámec domluvené spolupráce. V případě další kampaně v online prostředí bychom volili opět igloonet.",
      },
      {
        id: 3,
        imageSrc: LogoSNBZ,
        imagePerson: ZezulaPerson,
        imageAlt: "Logo Snowboard Zezula",
        name: "Martin Zezula",
        position: "ředitel",
        linkHref: "/marketing/reference/snowboard-zezula",
        linkTitle: "jak více než 10 let pomáháme SZ být jedničkou na trhu",
        text: "Na igloonetu se mi líbí spojení profesionality s neformálností. Abych řekl pravdu, tak jste nám vytrhli trn z paty.",
      },
      {
        id: 4,
        imageSrc: LogoKiwi,
        imagePerson: KiwiPerson,
        imageAlt: "Logo Kiwi",
        name: "Lucie Pezlarová",
        position: "Head of Analytics Kiwi.com",
        linkHref: "/marketing/reference/kiwi",
        linkTitle: "s čím jsme pomáhali internímu týmu v Kiwi.com",
        text: "S Adamem spolupracujeme již od roku 2018. Pomáhá analytikům i marketingovým manažerům Kiwi.com nastavit vhodné postupy pro vyhodnocování celého portfolia marketingových aktivit tak, aby výsledný reporting dával smysl a hodnoty byly použitelné pro rozhodování. Adam je předním expertem a navíc důvěryhodným a organizovaným partnerem pro diskusi; spolupráce s ním si velmi vážím",
      },
    ]

    const whatWeOffer = [
      {
        id: 1,
        image: <IconConsulting width={116} height={116} />,
        title: "Náborové kampaně",
        text: "Oslovíme vaše kandidáty přes výkonnostní reklamu na sociálních sítích a ve vyhledávačích. Využijte svůj náborový rozpočet efektivněji.",
      },
      {
        id: 2,
        image: <IconBoard width={116} height={116} />,
        title: "Školení",
        text: "Náborovou kampaň zvládnete díky nám sami. Naučíme vás práci s marketingovými nástroji a ukážeme, jaká data můžete v náborových kampaních využívat.",
      },
      {
        id: 3,
        image: <IconWebpage width={116} height={116} />,
        title: "Kariérní stránky",
        text: "Zaujměte kandidáty moderním kariérním webem. Vytvoříme pro vás stránky založené na analytice a výzkumu.",
      },
    ]

    const personSlider = [
      {
        id: 1,
        imageSrc: ProfileAdam,
        name: "Adam Šilhan",
        position: "10+ let zkušeností v online marketingu",
        text: "Google Certified Trainer oblasti Automation and Attribution, Exponea certifikace, hlavním záběrem je marketingová analytika, učí Webovou analytiku na Masarykově Univerzitě",
      },
      {
        id: 2,
        imageSrc: ProfileLukas,
        name: "Lukáš Havlík",
        position: "7 let zkušeností v online marketingu",
        text: "vede marketingový tým, garant pro sociální sítě, hlavním záběrem je komunikace a kampaně na sociálních sítích, mentoruje a školí klientské týmy",
      },
      {
        id: 3,
        imageSrc: ProfileKlaudie,
        name: "Klaudie Semelová",
        position: "6 let zkušeností v online marketingu",
        text: "garant pro PPC, vede větší marketingové projekty, má zkušenosti s komunikací jak s marcom, tak IT odděleními",
      },
      {
        id: 4,
        imageSrc: ProfileMyval,
        name: "Marek Pentoň",
        position: "6 let zkušeností ve vývoji / online marketingu",
        text: "garant pro e-mailing, Exponea certifikace, technický přesah - dělá na automatizačních nástrojích pro igloo (práce s APIs reklamních platforem)",
      },
      {
        id: 5,
        imageSrc: ProfileDavid,
        name: "David Pohorský",
        position: "10+ let zkušeností v online marketingu",
        text: "PPC specialista, analytické myšlení a no bullshit přístup, mentoruje a školí klientské týmy",
      },
    ]

    return (
      <Layout>
        <Helmet>
          <title>HR marketing | igloonet</title>
          <meta
            name="description"
            content={`Získejte uchazeče s využitím digitálního marketingu. V online světě se pohybujeme už ${already(
              2017
            )}. Náborové kampaně, školení hr marketingu i kariérní stránky.`}
          />
        </Helmet>
        <Banner>
          <h1>HR marketing od marketérů</h1>
          <p>
            V online světě se pohybujeme už {already(2007)}. Pomáháme firmám i
            školám získat uchazeče s využitím moderních technik digitálního
            marketingu.
          </p>
        </Banner>

        <TextBoxImageTop data={whatWeOffer} />

        <TechNav>
          <Container>
            <Nav tabs fill>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "1",
                  })}
                  onClick={() => {
                    this.toggle("1")
                  }}
                >
                  Víc o kampaních
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "2",
                  })}
                  onClick={() => {
                    this.toggle("2")
                  }}
                >
                  Víc o školeních
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "3",
                  })}
                  onClick={() => {
                    this.toggle("3")
                  }}
                >
                  Víc o stránkách
                </NavLink>
              </NavItem>
            </Nav>
          </Container>
        </TechNav>

        <TechWrapper>
          <Container>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <TitleH2Center>
                  Proč chtít náborovou kampaň od nás
                </TitleH2Center>

                <EdgeBorderFluid>
                  <Row className="mb-4">
                    <CodexItem
                      icon={<IconCv width={32} height={32} />}
                      title="Více si vybírejte"
                    >
                      Oslovte uchazeče, na které byste normálně nedosáhli.{" "}
                      Kampaní spuštěnou na několika marketingových kanálech{" "}
                      <strong>zaujmete více relevantních kandidátů.</strong>
                    </CodexItem>
                    <CodexItem
                      icon={<IconTarget width={32} height={32} />}
                      title="Pohled zvenčí"
                    >
                      Vžít se do kandidátů na různé pozice je složité.{" "}
                      <strong>
                        Pomůžeme zpětnou vazbou i bohatými zkušenostmi
                      </strong>{" "}
                      z předchozích kampaní.
                    </CodexItem>
                    <CodexItem
                      icon={<IconRocket width={32} height={32} />}
                      title="Rychlejší efekt"
                    >
                      Růst přílivu životopisů můžete očekávat už v{" "}
                      <strong>prvních dnech kampaně</strong>. Propagaci s vámi
                      pravidelně procházíme, vyhodnocujeme a upravujeme.
                    </CodexItem>
                    <CodexItem
                      icon={<IconConnect width={32} height={32} />}
                      title="Vyšší viditelnost"
                    >
                      Kampaní{" "}
                      <strong>zasáhneme desítky až stovky tisíc lidí</strong>.
                      Přítomnost v online světě navíc zlepší výkon ostatních
                      náborových kanálů. Dostanete se také k lidem, kteří práci
                      zatím aktivně nehledají.
                    </CodexItem>
                  </Row>

                  <div className="text-center">
                    <Button to="#form" style={ButtonStyle.Primary}>
                      Chci náborovou kampaň
                    </Button>
                  </div>
                </EdgeBorderFluid>
              </TabPane>

              <TabPane tabId="2">
                <TitleH2Center>Proč chtít školení od nás</TitleH2Center>

                <EdgeBorderFluid>
                  <Row className="mb-4">
                    <CodexItem
                      icon={<IconMarketing width={32} height={32} />}
                      title="Marketing od marketérů"
                    >
                      Školit vás budou lidé, co se problematice věnují{" "}
                      <strong>5 a více let</strong>. Naučíte se pracovat s
                      placenou reklamou a správně komunikovat na sociálních
                      sítích.
                    </CodexItem>

                    <CodexItem
                      icon={<IconNetwork width={32} height={32} />}
                      title="Zapojíme vás"
                    >
                      Jsme zvyklí školit začátečníky i ostřílené marketéry.
                      Vyzkoušíte si <strong>prakticky</strong>, jak marketingové
                      nástroje fungují, a budete je umět{" "}
                      <strong>sami používat</strong>.
                    </CodexItem>

                    <CodexItem
                      icon={<IconBoard width={32} height={32} />}
                      title="Ušijeme vám školení na míru"
                    >
                      Školení stavíme přesně pro vás a učíme{" "}
                      <strong> jen to, co opravdu využijete</strong>. Vybíráme
                      ty marketingové kanály, které potenciální uchazeči
                      používají.
                    </CodexItem>

                    <CodexItem
                      icon={<IconConsulting width={32} height={32} />}
                      title="Podpoříme vás"
                    >
                      Rádi s klienty <strong> pracujeme dlouhodobě</strong> a
                      pomáháme jim poznatky
                      <strong> uvést do praxe</strong>. I v případě
                      jednorázového školení vás rádi podpoříme, kdykoli se na
                      nás obrátíte.
                    </CodexItem>
                  </Row>

                  <div className="text-center">
                    <Button to="#form" style={ButtonStyle.Primary}>
                      Chci školení
                    </Button>
                  </div>
                </EdgeBorderFluid>
              </TabPane>

              <TabPane tabId="3">
                <TitleH2Center>Čím se kariérní stránky vyznačují</TitleH2Center>

                <EdgeBorderFluid>
                  <Row className="mb-4">
                    <CodexItem
                      icon={<IconResearch width={32} height={32} />}
                      title="Založené na výzkumu"
                    >
                      Stránky vytváříme na základě{" "}
                      <strong> důkladného uživatelského výzkumu</strong>,
                      znalosti vašich zákazníků a konkurence. Používáme metody
                      jako uživatelské testování, hloubkové rozhovory, customer
                      journey nebo persony.
                    </CodexItem>

                    <CodexItem
                      icon={<IconResponsive width={32} height={32} />}
                      title="Plně responzivní"
                    >
                      Všechny stránky poběží skvěle nejen v různých prohlížečích
                      a rozlišeních, ale také na{" "}
                      <strong>mobilech a tabletech</strong>.
                    </CodexItem>

                    <CodexItem
                      icon={<IconRocket width={32} height={32} />}
                      title="S příběhem, který přiláká"
                    >
                      <strong>Hodnoty, mise, cíle</strong>. Přilákat ty správné
                      kandidáty znamená nabídnout benefity, ale také ukázat
                      prostředí firmy takové, jaké skutečně je. Pomůžeme vám s
                      prezentací firmy tak, aby byla{" "}
                      <strong>autentická a poutavá</strong>.
                    </CodexItem>

                    <CodexItem
                      icon={<IconAnalytica width={32} height={32} />}
                      title="S marketingovou analytikou"
                    >
                      Každou stránku dodáme s{" "}
                      <strong> kompletně funkční analytikou</strong>. Od
                      nástrojů pro vyhodnocování{" "}
                      <strong> účinnosti stránek a kampaní </strong>
                      až po sledování toho, jak se{" "}
                      <strong> návštěvníci na webu chovají</strong>.
                    </CodexItem>
                  </Row>

                  <div className="text-center">
                    <Button to="#form" style={ButtonStyle.Primary}>
                      Chci kariérní stránku
                    </Button>
                  </div>
                </EdgeBorderFluid>
              </TabPane>
            </TabContent>
          </Container>
        </TechWrapper>

        <Container>
          <BlockImageTextHalf
            image={BrandImageKiwi_1x}
            imageSrcSet={`${BrandImageKiwi_1x},${BrandImageKiwi_2x} 2x`}
            alt=""
            width="538"
            height="357"
            buttonText={"Celý příběh"}
            buttonLink={"/marketing/reference/kiwi/"}
          >
            <LogoHeading imagePath={LogoKiwi} imageAlt="Logo kiwi.com">
              Konzultujeme propojení marketingu a dat pro{" "}
              <TextPrimary>kiwi.com</TextPrimary>
            </LogoHeading>

            <Hashtags>#mentoring #analytika #e-mailing #data</Hashtags>
          </BlockImageTextHalf>

          <BlockImageTextHalf
            image={BrandImageSA}
            alt={""}
            buttonText={"Celý příběh"}
            buttonLink={"/marketing/reference/student-agency/"}
            right
          >
            <LogoHeading imagePath={LogoRegiojet} imageAlt="Logo Regiojet">
              Proškolili jsme interní tým{" "}
              <TextPrimary>Student Agency</TextPrimary>
            </LogoHeading>

            <Hashtags>#performance_marketing #interní_školení</Hashtags>
          </BlockImageTextHalf>

          <BlockImageTextHalf
            image={BrandImageVUT}
            alt={""}
            buttonText={"Celý příběh"}
            buttonLink={"/marketing/reference/fsivut/"}
          >
            <LogoHeading imagePath={LogoVUT} imageAlt="Logo VUT">
              Zastavili jsme úbytek přihlášek pro <TextPrimary>VUT</TextPrimary>
            </LogoHeading>

            <Hashtags>
              #náborové_kampaně #sociální_sítě #interní_školení
            </Hashtags>
          </BlockImageTextHalf>

          <BlockImageTextHalf image={BrandImageTmobile} alt={""} right>
            <LogoHeading imagePath={LogoTMobile} imageAlt="Logo T-Mobile">
              Pro <TextPrimary>T‑Mobile</TextPrimary> jsme připravili odbornou
              část tendru na online agenturu
            </LogoHeading>

            <Hashtags>#konzultace #performance_marketing</Hashtags>
          </BlockImageTextHalf>

          <BlockImageTextHalf
            image={BrandImageIFE_1x}
            imageSrcSet={`${BrandImageIFE_1x},${BrandImageIFE_2x} 2x`}
            width="538"
            height="359"
            alt={"IFE - obrázek stroje"}
            buttonText={"Celý příběh"}
            buttonLink={"/marketing/reference/ife/"}
          >
            <LogoHeading imagePath={LogoIFE} imageAlt="Logo IFE">
              Pomáháme <TextPrimary>IFE</TextPrimary> obsadit desítky pozic díky
              online reklamě
            </LogoHeading>

            <Hashtags>#náborové_kampaně #performance_marketing</Hashtags>
          </BlockImageTextHalf>
        </Container>

        <ReferencesUsers title={"Naši zákazníci"} data={usersReferences} />

        <Slider title={"Poznejte náš tým"} data={personSlider} />

        <ContactForm contact="adam" />
      </Layout>
    )
  }
}
